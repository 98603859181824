<template>
  <div class="page--limited card">
    <header>

      <div class="p-d-flex p-ai-center">
        <router-link :to="backRoute">
          <Button
              icon="pi pi-arrow-left"
              class="p-mb-0"
              v-tooltip.bottom="$t('back')"
          />
        </router-link>
      </div>

      <div class="p-d-flex p-ai-center">
        <h1 v-if="isNew">{{ $t('material.title_new') }}</h1>
        <h1 v-else>{{ $t('material.title_edit') }}</h1>
      </div>

      <div class="p-d-flex p-ai-center">
        <Button
            icon="pi pi-save"
            class="p-mr-1"
            v-tooltip.bottom="$t('save')"
            @click="onSave"
        />

        <Button
            icon="pi pi-trash"
            class="p-button-danger"
            v-tooltip.bottom="$t('delete')"
            @click="onDelete"
        />
      </div>

    </header>

    <template v-if="requestPending || !material">
      <div class="p-formgroup">
        <div class="p-field p-fluid">
          <Skeleton
              height="2rem"
              class="p-mb-2"
          />
        </div>
        <div class="p-field p-fluid">
          <Skeleton
              height="5rem"
              class="p-mb-2"
          />
        </div>
      </div>
    </template>

    <template v-else-if="material">
      <div class="p-formgroup">

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('material.name')">
            <InputText v-model="material.name" />
          </FloatLabel>
        </div>

        <div class="p-field p-fluid">
          <FloatLabel :label="$t('material.notes')">
            <Textarea v-model="material.note" />
          </FloatLabel>
        </div>

      </div>
    </template>

  </div>
</template>
<script type="ts" src="./MaterialPage.ts" />