import { Options, Prop, Vue } from "vue-property-decorator";
import {MaterialsRoutesEnum} from "../../router";
import {Material} from "@/model/api/Material";
import {materialsService} from "@services/materials.service";


@Options({
})
export default class MaterialPage extends Vue {
    @Prop() readonly materialId!: string;

    material: Material = null;

    backRoute = { name: MaterialsRoutesEnum.MATERIALS_LIST }

    isNew = this.materialId === 'new'

    onSave() {
        this.$waitFor(
            async () => {
                if (this.isNew) {
                    const response = await materialsService.create(this.material);

                    this.$router.replace({
                        name: MaterialsRoutesEnum.MATERIALS_DETAIL,
                        params: { materialId: response.id }
                    }).then(() =>
                        this.$successMessage(this.$t(`material.messages.create_success`))
                    );
                } else {
                    await materialsService.updatePatch(this.material);
                    this.$successMessage(this.$t(`material.messages.update_success`));
                }

            },
            this.$t('Operation failed')
        );
    }

    onDelete(){
        this.$confirmMessage("Are you sure to delete this Material?")
            .then( r => {
                if (r) {
                    this.deleteMaterial();
                }
            })
    }

    private deleteMaterial(){
        this.$waitFor(
            async () => {
                await materialsService.deleteBySystem(this.material);

                this.$router.replace({ name: MaterialsRoutesEnum.MATERIALS_LIST })
                    .then( () =>
                        this.$successMessage("Material successfully deleted")
                    );
            },

            "Deleting Material failed"
        )
    }

    private _loadMaterial() {
        return this.$waitFor(
            async () => {
                this.material = await materialsService.getById(+this.materialId);
            },
            "Material not found"
        )
    }

    async created() {
        if (this.isNew) {
            this.material = new Material();
        } else {
            await this._loadMaterial();
        }
    }

}